@font-face {
  font-family: 'Tim Sans';
  src: url('../fonts/TIMSans-Light.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Tim Sans';
  src: url('../fonts/TIMSans-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Tim Sans';
  src: url('../fonts/TIMSans-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Tim Sans';
  src: url('../fonts/TIMSans-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Tim Sans';
  src: url('../fonts/TIMSans-BoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}
