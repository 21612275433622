@font-face {
  font-family: "Tim Sans";
  src: url("../fonts/TIMSans-Light.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Tim Sans";
  src: url("../fonts/TIMSans-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Tim Sans";
  src: url("../fonts/TIMSans-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Tim Sans";
  src: url("../fonts/TIMSans-Bold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Tim Sans";
  src: url("../fonts/TIMSans-BoldItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}
.btn {
  color: #fff;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1rem;
  background: #0026da;
  display: block;
  padding: 0.75rem 1.5rem;
  border-radius: 7px;
}
.btn.sec {
  background: #052797;
}
.btn:hover {
  background: #fff;
  color: #0026da;
  box-shadow: 0 0 0 2px rgba(0, 36, 218, 0.275);
}
.btn:hover.sec {
  color: #052797;
}
.btn:disabled, .btn.disabled {
  position: relative;
  opacity: 0.75;
  pointer-events: none;
  color: #fff;
  overflow: hidden;
}
.btn:disabled:not(.notLoading), .btn.disabled:not(.notLoading) {
  cursor: progress;
}
.btn:disabled:not(.notLoading):after, .btn.disabled:not(.notLoading):after {
  content: "";
  position: absolute;
  bottom: 3px;
  height: 4px;
  background-color: #fff;
  border-radius: 4px;
  animation: loadbar 1s ease-out infinite;
}
.btn.btn-white {
  background-color: #fff;
  color: #000000;
  font-size: 14px;
  padding: 16px, 24px, 16px, 24px;
  min-width: 300px;
}

@keyframes loadbar {
  0% {
    left: 0;
    right: 100%;
  }
  25% {
    left: 0;
    right: 15%;
  }
  100% {
    left: 100%;
    right: 0;
  }
}
@keyframes loadRot {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes drop {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes wave {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}
* {
  outline: unset !important;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Tim Sans", sans-serif;
  font-size: 16px;
  background-color: #0026da;
  color: #fff;
}
body.no-scroll {
  overflow: hidden;
}

.link {
  color: #fff;
  margin-top: 15px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.7;
}

.alert-error {
  color: #E10A17;
  font-size: 14px;
}

.popUpField {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 101;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popUpField.popup-tc .popBox {
  max-width: 750px;
  width: 75%;
  height: 50vh;
  margin-left: 1.5em;
  margin-right: 1.5em;
}
.popUpField.popup-tc .popBox .popHeader {
  padding: 0px;
}
.popUpField.popup-tc .popBox .popbody {
  height: 75%;
}
.popUpField.popup-tc .popBox .popbody iframe {
  height: 100%;
}
.popUpField .popBox {
  background: radial-gradient(#0C54C2, #062A61);
  text-align: center;
  position: relative;
  border-radius: 10px;
  max-width: 375px;
  text-align: left;
  padding: 15px;
  position: absolute;
  margin-left: 1.5em;
  margin-right: 1.5em;
}
.popUpField .popBox.success-popup {
  background: linear-gradient(150deg, #000, #0336A7, #103FFF);
}
.popUpField .popBox .image {
  text-align: center;
}
.popUpField .popBox .image img {
  max-width: 100%;
  max-height: 200px;
}
.popUpField .popBox .close-popUp {
  position: absolute;
  padding: 5px;
  color: #fff;
  right: 5px;
  top: 5px;
}
.popUpField .popBox .contentBox {
  max-height: 70vh;
  overflow: auto;
  min-height: 160px;
  font-size: 12px;
  padding: 30px 15px;
}
.popUpField .popBox .contentBox .row {
  margin: 10px 0;
}
.popUpField .popBox .popHeader {
  text-align: center;
  padding: 10px 15px;
  color: #fff;
  font-size: 16px;
}
.popUpField .popBox .popHeader .title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.popUpField .popBox .popHeader .popup-title {
  font-family: TimSansLight;
  font-size: 21px;
}
.popUpField .popBox .popHeader .popup-subtitle {
  font-size: 1.3em;
  text-transform: uppercase;
}
.popUpField .popBox .popFooter {
  padding: 15px;
  border-radius: 0px 0px 8px 8px;
  text-align: center;
}
.popUpField .popBox .popFooter p {
  margin: 10px 0;
  font-size: 12px;
}

/*----- CONTENT LOADER -----*/
.contentLoader span:after, .contentLoader span:before {
  content: "";
  position: absolute;
  border-radius: inherit;
  width: 34%;
  height: 34%;
  top: 33%;
  left: 33%;
}

.contentLoader {
  margin: 6rem 0;
}
.contentLoader span {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
  position: relative;
  z-index: 1;
  border-radius: 50%;
}
.contentLoader span:before {
  background-color: #fff;
  animation: wave 1s infinite ease-out;
}
.contentLoader span:after {
  background-color: #fff;
  animation: drop 1s infinite ease-in;
}

#appBase {
  padding-top: 50px;
}
#appBase header {
  background-color: #052f3d;
  border-bottom: 1px solid #246884;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
}
#appBase header .container-xl {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#appBase header .container-xl img {
  display: inline-block;
  height: 25px;
  cursor: pointer;
}
#appBase header .container-xl a {
  display: inline-block;
  cursor: pointer;
  font-size: 0.85em;
}
#appBase .mainBanner {
  position: relative;
  background-color: #052f3d;
}
#appBase .mainBanner .banner {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  object-fit: cover;
  object-position: top right;
  display: block;
}
#appBase .mainBanner .bannerInfo {
  display: inline-block;
  width: 50%;
  padding: 3rem 1rem 3rem 0;
  text-align: left;
}
#appBase .mainBanner .bannerInfo h1 {
  font-size: 1.68em;
  font-weight: 600;
  margin-bottom: 1rem;
}
#appBase .mainBanner .bannerInfo h6 {
  font-size: 1.1em;
  font-weight: 200;
  margin-bottom: 2rem;
}
#appBase .mainBanner .bannerInfo .btn {
  display: inline-block;
}
#appBase .mainBanner .banner_2 {
  display: inline-block;
  max-height: 260px;
  max-width: 25%;
  object-fit: contain;
  vertical-align: bottom;
  margin-left: 25%;
}
@media (max-width: 700px) {
  #appBase .mainBanner {
    text-align: center;
  }
  #appBase .mainBanner .banner {
    position: initial;
    height: 200px;
    margin: 0 -0.75rem;
    width: calc(100% + 1.5rem);
  }
  #appBase .mainBanner .bannerInfo {
    width: 100%;
  }
  #appBase .mainBanner .banner_2 {
    max-width: 75%;
    margin-left: 0;
  }
}
#appBase .know .container-xl {
  display: flex;
}
#appBase .know .container-xl > div {
  display: inline-block;
  padding: 2rem 0;
}
#appBase .know .container-xl > div:first-child {
  width: 40%;
  margin: auto 0;
}
#appBase .know .container-xl > div:nth-child(2) {
  width: 35%;
  margin-bottom: auto;
}
#appBase .know .container-xl > div:last-child {
  width: 25%;
  padding: 0;
  margin-top: auto;
}
#appBase .know .container-xl h6 {
  font-size: 1em;
  font-weight: 600;
}
#appBase .know .container-xl h2 {
  font-size: 1.75em;
  font-weight: 400;
}
#appBase .know .container-xl .btn {
  display: inline-block;
  margin-top: 2rem;
}
#appBase .know .container-xl .tip {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 0.5rem;
}
#appBase .know .container-xl .tip img {
  display: inline-block;
  width: 65px;
  margin-bottom: 0.5rem;
}
#appBase .know .container-xl .tip p {
  font-size: 0.9em;
  margin-bottom: 0.75rem;
}
#appBase .know .container-xl .tip a {
  color: #fff;
  cursor: pointer;
}
#appBase .know .container-xl .banner_2 {
  width: 100%;
  max-width: 250px;
}
@media (max-width: 700px) {
  #appBase .know .container-xl {
    flex-wrap: wrap;
  }
  #appBase .know .container-xl > div:first-child {
    width: 100%;
    margin: 0;
  }
  #appBase .know .container-xl > div:nth-child(2) {
    width: 100%;
    margin: 0;
    padding-top: 0;
  }
  #appBase .know .container-xl > div:last-child {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
#appBase .shortBennefits, #appBase .allBennefits {
  background-color: #002199;
  padding: 3rem 0;
}
#appBase .shortBennefits h4, #appBase .allBennefits h4 {
  font-weight: 400;
  font-size: 1.35em;
  margin-bottom: 1.5rem;
}
#appBase .shortBennefits .homeCarousel .slideItem, #appBase .shortBennefits .bennefitsBox .slideItem, #appBase .allBennefits .homeCarousel .slideItem, #appBase .allBennefits .bennefitsBox .slideItem {
  background-color: #fff;
  color: #000;
  border-radius: 15px;
  margin: 0 1rem;
  padding-top: 1rem;
  overflow: hidden;
  width: 230px;
}
#appBase .shortBennefits .homeCarousel .slideItem img, #appBase .shortBennefits .bennefitsBox .slideItem img, #appBase .allBennefits .homeCarousel .slideItem img, #appBase .allBennefits .bennefitsBox .slideItem img {
  display: block;
  width: calc(100% - 2rem);
  height: 200px;
  margin: 0 auto;
  object-fit: contain;
}
#appBase .shortBennefits .homeCarousel .slideItem .info, #appBase .shortBennefits .bennefitsBox .slideItem .info, #appBase .allBennefits .homeCarousel .slideItem .info, #appBase .allBennefits .bennefitsBox .slideItem .info {
  background-color: #efefef;
  display: flex;
  padding: 1rem;
  align-items: center;
  margin-top: 1rem;
  text-align: left;
}
#appBase .shortBennefits .homeCarousel .slideItem .info h5, #appBase .shortBennefits .bennefitsBox .slideItem .info h5, #appBase .allBennefits .homeCarousel .slideItem .info h5, #appBase .allBennefits .bennefitsBox .slideItem .info h5 {
  font-size: 0.7em;
  font-weight: 400;
  width: 100%;
  padding-right: 0.5rem;
  margin: 0;
}
#appBase .shortBennefits .homeCarousel .slideItem .info h5 span, #appBase .shortBennefits .bennefitsBox .slideItem .info h5 span, #appBase .allBennefits .homeCarousel .slideItem .info h5 span, #appBase .allBennefits .bennefitsBox .slideItem .info h5 span {
  display: block;
  color: #0026da;
  font-size: 0.9em;
  font-weight: 600;
}
#appBase .shortBennefits .homeCarousel .slideItem .info .btn, #appBase .shortBennefits .bennefitsBox .slideItem .info .btn, #appBase .allBennefits .homeCarousel .slideItem .info .btn, #appBase .allBennefits .bennefitsBox .slideItem .info .btn {
  padding: 0.5rem 1rem;
  font-size: 0.7em;
}
#appBase .shortBennefits .bennefitsBox, #appBase .allBennefits .bennefitsBox {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}
#appBase .shortBennefits .bennefitsBox .slideItem, #appBase .allBennefits .bennefitsBox .slideItem {
  width: calc(25% - 2rem);
  min-width: 200px;
  margin: 0;
}
@media (max-width: 500px) {
  #appBase .shortBennefits .bennefitsBox .slideItem, #appBase .allBennefits .bennefitsBox .slideItem {
    width: calc(50% - .75rem);
    min-width: unset;
  }
  #appBase .shortBennefits .bennefitsBox .slideItem img, #appBase .allBennefits .bennefitsBox .slideItem img {
    height: 150px;
  }
  #appBase .shortBennefits .bennefitsBox .slideItem .info, #appBase .allBennefits .bennefitsBox .slideItem .info {
    flex-wrap: wrap;
  }
  #appBase .shortBennefits .bennefitsBox .slideItem .info h5, #appBase .allBennefits .bennefitsBox .slideItem .info h5 {
    padding-right: 0;
    padding-bottom: 0.5rem;
  }
  #appBase .shortBennefits .bennefitsBox .slideItem .info .btn, #appBase .allBennefits .bennefitsBox .slideItem .info .btn {
    width: 100%;
  }
}
#appBase .shortBennefits .soloBtn, #appBase .allBennefits .soloBtn {
  margin-top: 3rem;
}
#appBase .shortBennefits .soloBtn .btn, #appBase .allBennefits .soloBtn .btn {
  max-width: 200px;
  margin: 0 auto;
}
#appBase .shortBennefits .paginationNav, #appBase .allBennefits .paginationNav {
  text-align: center;
  font-size: 0.75em;
  margin-top: 1.5rem;
}
#appBase .shortBennefits .paginationNav span, #appBase .allBennefits .paginationNav span {
  display: inline-block;
  margin: 0 0.125rem;
  padding: 0.25rem;
  cursor: pointer;
}
#appBase .shortBennefits .paginationNav span.select, #appBase .shortBennefits .paginationNav span:hover, #appBase .allBennefits .paginationNav span.select, #appBase .allBennefits .paginationNav span:hover {
  text-decoration: underline;
}
#appBase .shortBennefits .paginationNav span.disabled, #appBase .allBennefits .paginationNav span.disabled {
  pointer-events: none;
  opacity: 0.5;
}
#appBase footer {
  position: relative;
  padding: 3rem 0;
}
#appBase footer .appLinks {
  position: absolute;
  min-height: 100%;
  min-width: 350px;
  top: 0;
  right: 0;
  background-color: black;
  background-image: url(./assets/images/footerBackground.png);
  background-position: center;
  background-size: cover;
  padding: 3rem 5rem;
  text-align: center;
}
#appBase footer .appLinks .logo {
  width: 100px;
  display: block;
  margin: 0 auto 1rem;
}
#appBase footer .appLinks p {
  font-size: 0.8em;
  font-weight: 200;
  margin-bottom: 1rem;
}
#appBase footer .appLinks a {
  display: inline-block;
  margin: 0 1rem;
  color: #fff;
  text-decoration: none;
  font-size: 1.75em;
  cursor: pointer;
}
#appBase footer .linksUteis {
  display: inline-block;
}
#appBase footer .linksUteis > a {
  text-decoration: none;
  display: block;
  color: #fff;
  font-size: 0.8em;
  margin-bottom: 0.5rem;
}
#appBase footer .linksUteis > a:hover {
  text-decoration: underline;
}
#appBase footer .linksUteis h5 {
  font-size: 1.1em;
  margin: 2rem 0 1rem;
}
#appBase footer .linksUteis .social a {
  color: #fff;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: 1.5em;
}
@media (max-width: 700px) {
  #appBase footer {
    padding-bottom: 0;
  }
  #appBase footer .appLinks {
    position: initial;
    min-height: unset;
    min-width: unset;
    width: calc(100% + 1.5rem);
    margin: 2rem -0.75rem 0;
  }
}
#appBase .popupBennefit {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  display: flex;
}
#appBase .popupBennefit .popbox {
  margin: auto 0;
  width: 100%;
  background-color: #fff;
  color: #0026da;
}
#appBase .popupBennefit .popbox > .container-xl {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
#appBase .popupBennefit .popbox > .container-xl .closeBtn {
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  font-size: 0.9em;
  padding: 0.1rem 0.25rem;
  line-height: 1;
  background-color: #c7c7c7;
  color: #707070;
  cursor: pointer;
}
#appBase .popupBennefit .popbox > .container-xl .partnerlogo {
  background-color: #fff;
  border-radius: 10px;
  height: 175px;
  width: 275px;
  max-width: 100%;
  object-fit: contain;
  padding: 1.5rem;
  margin: 0 auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}
#appBase .popupBennefit .popbox > .container-xl .info {
  width: 50%;
  padding-right: 1rem;
}
#appBase .popupBennefit .popbox > .container-xl .info h3 {
  font-weight: 400;
}
#appBase .popupBennefit .popbox > .container-xl .info p {
  font-size: 0.8em;
}
#appBase .popupBennefit .popbox > .container-xl .info input {
  background: none;
  border: 1px solid #0026da;
  color: #0026da;
  border-radius: 3px;
  padding: 0.25rem 1rem;
  display: inline-block;
  margin-bottom: 1rem;
  max-width: 100%;
}
#appBase .popupBennefit .popbox > .container-xl .info input + .icon {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-left: -30px;
  pointer-events: none;
}
#appBase .popupBennefit .popbox > .container-xl .info .loader {
  display: block;
  width: 34px;
  height: 34px;
  border: 2px solid #0026da;
  border-radius: 17px;
  border-top-color: transparent;
  margin-bottom: 1rem;
  animation: loadRot linear 1s infinite;
}
@media (max-width: 600px) {
  #appBase .popupBennefit .popbox > .container-xl {
    flex-wrap: wrap-reverse;
  }
  #appBase .popupBennefit .popbox > .container-xl .info {
    width: 100%;
    padding: 0;
    margin-top: 1.5rem;
  }
}
#appBase .popupBennefit .popbox .disclaimer {
  background-color: #ebebeb;
}
#appBase .popupBennefit .popbox .disclaimer .container-xl {
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: #000;
}
#appBase .popupBennefit .popbox .disclaimer .container-xl h3 {
  padding-right: 1rem;
  margin: 0;
  font-size: 1.2em;
}
#appBase .popupBennefit .popbox .disclaimer .container-xl p {
  width: 100%;
  margin: 0;
  font-size: 0.8em;
}
#appBase .popBase {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
  background-color: rgba(7, 47, 61, 0.9);
  z-index: 1;
  display: flex;
  padding: 1.5rem;
  overflow-y: auto;
}
#appBase .popBase .popBox {
  background-color: #042199;
  margin: auto;
  padding: 0 1.5rem 1.5rem;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  text-align: center;
}
#appBase .popBase .popBox img.header {
  display: block;
  width: calc(63.627% + 3rem);
  margin: 0 -1.5rem -3.375%;
  position: relative;
  z-index: 1;
}
#appBase .popBase .popBox .top {
  background-color: #0827DA;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  position: relative;
  z-index: 0;
  text-align: initial;
}
#appBase .popBase .popBox .top p {
  display: block;
  width: 70%;
  margin: 0;
}
#appBase .popBase .popBox .top p span {
  color: #FECC2F;
}
#appBase .popBase .popBox .top img {
  position: absolute;
  top: 50%;
  right: 5%;
  width: 25%;
  transform: translateY(-50%);
}
#appBase .popBase .popBox .choises {
  display: flex;
  margin: 1.25rem auto 3rem;
}
#appBase .popBase .popBox .choises .sideCol {
  width: 50%;
  padding: 3rem 1.5rem;
  background-color: #032F45;
}
#appBase .popBase .popBox .choises .sideCol h1 {
  margin: 0;
}
#appBase .popBase .popBox .choises .sideCol h1 b {
  color: #FC3147;
}
#appBase .popBase .popBox .choises .sideCol h5 {
  margin: 0;
  font-size: 1.1em;
  font-weight: 600;
}
#appBase .popBase .popBox .choises .sideCol p {
  opacity: 0.8;
  margin: 1rem auto 1.25rem;
  font-size: 14px;
  line-height: 1.4em;
}
#appBase .popBase .popBox .choises .sideCol input {
  display: block;
  margin: 1rem auto 0.75rem;
  background: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  text-align: center;
  width: 90%;
}
#appBase .popBase .popBox .choises .sideCol input.alertInputError {
  border-bottom: 2px solid red;
}
#appBase .popBase .popBox .choises .sideCol input.alertInputError:after {
  content: "*";
  color: red;
}
#appBase .popBase .popBox .choises .sideCol .btn {
  display: inline-block;
  font-weight: 600;
}
#appBase .popBase .popBox .choises .sideCol:last-child {
  background-color: #061F88;
}
#appBase .popBase .popBox .choises .sideCol:last-child h1 b {
  color: #FC2DA9;
}
#appBase .popBase .popBox .choises .sideCol:last-child p {
  opacity: 0.9;
}
#appBase .popBase .popBox > a {
  color: rgba(255, 255, 255, 0.7);
}
#appBase .popBase .popBox .disclamer {
  color: #FECB2F;
  font-size: 0.8em;
}
@media (max-width: 600px) {
  #appBase .popBase .popBox img.header {
    width: calc(95% + 3rem);
  }
  #appBase .popBase .popBox .choises {
    flex-wrap: wrap;
  }
  #appBase .popBase .popBox .choises .sideCol {
    width: 100%;
  }
  #appBase .popBase .popBox .top p {
    width: 100%;
  }
  #appBase .popBase .popBox .top img {
    position: initial;
    top: unset;
    right: unset;
    width: 35%;
    display: block;
    margin: 0 auto 1rem;
    transform: none;
  }
}
#appBase .cookieBasket {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #efefef;
  color: #404040;
  font-size: 0.9em;
  padding: 2rem 0;
}
#appBase .cookieBasket .container-xl {
  display: flex;
  align-items: center;
  justify-content: center;
}
#appBase .cookieBasket .container-xl p {
  margin: 0;
}
#appBase .cookieBasket .container-xl button {
  background: #0026d9;
  border: none;
  border-radius: 7px;
  padding: 0.5rem 1.5rem;
  color: #fff;
  margin: 0 2rem;
}
@media (max-width: 630px) {
  #appBase .cookieBasket .container-xl {
    flex-wrap: wrap;
  }
  #appBase .cookieBasket .container-xl button {
    margin-top: 2em;
  }
}