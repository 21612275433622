.btn {
    color: #fff;
    font-weight: 400;
    font-size: .8em;
    line-height: 1rem;
    background: #0026da;
    display: block;
    padding: .75rem 1.5rem;
    border-radius: 7px;

    &.sec { background: #052797; }

    &:hover {
        background: #fff;
        color: #0026da;
        box-shadow: 0 0 0 2px rgba(0, 36, 218, 0.275);

        &.sec { color: #052797; }
    }

    // &:focus { box-shadow: 0 0 0 .25rem rgba(244, 117, 33, 0.25); }

    &:disabled, &.disabled {
        position: relative;
        opacity: .75;
        pointer-events: none;
        color: #fff;
        overflow: hidden;
        
        &:not(.notLoading) {
            cursor: progress;

            &:after {
                content: '';
                position: absolute;
                bottom: 3px;
                height: 4px;
                background-color: #fff;
                border-radius: 4px;
                animation: loadbar 1s ease-out infinite;
            }
        }
    }

    &.btn-white{
        background-color: #fff;
        color: #000000;
        font-size: 14px;
        padding: 16px, 24px, 16px, 24px;
        min-width: 300px;
    }
}